import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/home";
import Nav from "./components/nav";
import Footer from "./components/footer";
import Kontakt from "./pages/kontakt";
import NadStawami from "./pages/nadStawami";
import Dworska from "./pages/dworska";
import Polna from "./pages/polna";
import Finansowanie from "./pages/finansowanie";

const navItems = {
  home: [
    { item: "Home", route: "/" },
    {
      item: "Inwestycje",
      subItems: [
        { item: "Nad Stawami", route: "/nad-stawami" },
        { item: "Osiedle Dworska", route: "/osiedle-dworska" },
        { item: "Osiedle Polna", route: "/osiedle-polna" },
      ],
    },
    { item: "Finansowanie", route: "/finansowanie" },
    { item: "Kontakt", route: "/kontakt" },
  ],
};

const mobileNavItems = {
  home: [
    { item: "Home", route: "/" },
    { item: "Nad Stawami", route: "/nad-stawami" },
    { item: "Osiedle Dworska", route: "/osiedle-dworska" },
    { item: "Osiedle Polna", route: "/osiedle-polna" },
    { item: "Finansowanie", route: "/finansowanie" },
    { item: "Kontakt", route: "/kontakt" },
  ]
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav navItems={navItems.home} mobileNavItems={mobileNavItems.home}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nad-stawami" element={<NadStawami />} />
          <Route path="/osiedle-dworska" element={<Dworska />} />
          <Route path="/osiedle-polna" element={<Polna />} />
          <Route path="/finansowanie" element={<Finansowanie />} />
          <Route path="/kontakt" element={<Kontakt />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;