import styled from "styled-components";
import { useState } from "react";

const MainPhoto = styled.div`
  background-image: url('/szczesne6.jpg');
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  width: 100%;
  margin-top: 6rem;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  > *:not(:first-child) {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const SecondHeading = styled.h2`
  text-align: center;
  margin-top: 6rem;
  font-size: 2.4rem;
  font-weight: 400;
`;

const Text = styled.p`
  text-align: center;
  margin-top: 2rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  line-height: 36px;
  text-align: justify;
  font-size: 1.1rem;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 0.9rem;
  }
  span {
    font-weight: 600;
  }
`;

const Map = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  margin-top: 6rem;
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 65%;
  margin: 4rem auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:nth-child(1) {
    grid-column: span 2;
    grid-row: span 2;
  }
  &:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }
  &:nth-child(3) {
    grid-column: span 1;
    grid-row: span 1;
  }
  &:nth-child(4) {
    grid-column: span 2;
    grid-row: span 1;
  }
  &:nth-child(5) {
    grid-column: span 1;
    grid-row: span 1;
  }

  @media (max-width: 768px) {
    grid-column: span 1 !important;
    grid-row: span 1 !important;
  }
`;

export const MainZalety = styled.div`
  padding: 2rem;
  width: 95%;
  margin: 4rem auto;
  background-color: #fff;
`;

export const ZaletyHeadingMain = styled.h2`
  text-align: center;
  margin-bottom: 4rem;
  font-size: 2rem;
  color: #333;
  font-weight: 400;
`;

export const ZaletyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
`;

export const Zaleta = styled.div`
  border: 1px solid #aaa;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const ZaletaIcon = styled.img`
  width: 50px;
  margin: 1rem auto;
`;

export const ZaletaHeading = styled.h3`
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #444;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ZaletaInfo = styled.p`
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;

  @media (max-width: 768px) {
    max-height: 50dvh;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;

  th,
  td {
    text-align: center;
    padding: 12px;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  td a {
    color: #007bff;
    text-decoration: none;
  }

  td a:hover {
    text-decoration: underline;
  }

  .taken {
    color: #ff5e5e;
    font-weight: bold;
  }
`;

const StyledTh = styled.th`
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
`;

const SpecyfikacjaContainer = styled.div`
  position: relative;
  padding: 1rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpecyfikacjaDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  background: rgb(42,112,212);
  background: linear-gradient(180deg, rgba(42,112,212,1) 3%, rgba(110,166,247,1) 49%);
  border-radius: 12px;
  padding: 1rem;
  -webkit-box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  width: 50%;
  height: 500px;
  overflow: auto;

  @media (max-width: 1599px) {
    width: 75%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 650px;
  }
`;

const SpecyfikacjaImage = styled.div`
  background-size: cover;
  background-position: center;
  flex: 1;
  border-radius: 6px;
  min-width: 320px;
  height: 420px;

  @media (max-width: 1024px) {
    height: 320px;
  }
`;

const SpecyfikacjaDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  min-width: 320px;
  padding: .5rem;
  color: white;

  h4 {
    font-size: 1.3rem;
    text-align: center;
  }

  p {
    font-size: 0.9rem;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.left {
    left: 20%;

    @media (max-width: 1599px) {
      left: 5%;
    }

    @media (max-width: 1024px) {
      left: 0%;
      font-size: 1rem;
      width: 24px;
      height: 24px;
    }
  }

  &.right {
    right: 20%;

    @media (max-width: 1599px) {
      right: 5%;
    }

    @media (max-width: 1024px) {
      right: 0%;
      font-size: 1rem;
      width: 24px;
      height: 24px;
    }
  }
`;

const specyfikacjaData = [
  {photo: '/dworska/osowiec_zewnątrz.jpg', heading: "Fundamenty, ściany piwnic, kondygnacji nadziemnych", text: "Budynek niepodpiwniczony, projektowane fundamenty – ławy fundamentowe żelbetowe (min C20/25, 60x40). Ściany fundamentowe żelbetowe, wylewane lub murowane z bloczków fundamentowych betonowych. Zbrojenie ław fundamentowych – 5 prętów fi12. Konstrukcja ścian nośnych zewnętrznych : Bloczki gazobetonowe gr.24cm, izolowane z zewnątrz styropianem typu EPS 032 Fasada Super (0,032 W/mK) wykończone tynkiem cienkowarstwowym na siatce. Konstrukcja ścian nośnych wewnętrznych – bloczki gazobetonowe gr. 24cm i bloczki silikatowe gr. 18cm, tynkowane. Ściany działowe z płyt g-k na stelarzach systemowych lub bloczków gazobetonowych gr. 12cm i 8cm."},
  {photo: '/dworska/osowiec_zewnatrz_dwa.jpg', heading: "Stropy, dach, wykończenie elewacji.", text: "Stropy między- kondygnacyjne łączące parter z górą żelbetowe prefabrykowane typu Teriva. Stropy oparte na ścianach zewnętrznych i wewnętrznych słupach konstrukcyjnych. Zbrojenie stropów wykonać wg. projektu wykonawczego konstrukcji. Dach projektowany - kopertowy o kącie nachylenia połaci 20st., o konstrukcji drewnianej kratownicowej , kryty blachą powlekaną. Dostęp do kominów przez wyłazy dachowe. Elewacja budynku wykonana w technologii tynku cienkowarstwowego, w kolorze szarym, wg. rys. elewacji. Detale wykończenia elewacji wg. rys. warsztatowych."},
  {photo: '/dworska/Osowiec_schody.jpg', heading: "Okna wykonane w systemie Salamander.", text: "Stolarka okienna zewnętrzna z profili PCV. Drzwi zewnętrzne wzmacniane, z dodatkową wkładką izolacyjną. Ławy fundamentowe – papa asfaltowa, Ściany fundamentowe – obustronnie abizol lub dysperbit – izolację pionową wyprowadzić do poziomu izolacji podłogi na gruncie. Izolacja termiczna ze styropianu EPS typu FUNDAMENT Ściany zewnętrzne – izolacja termiczna ze styropianu EPS typu FASADA Dach – folia wysokoparoprzepuszczalna, izolacja termiczna z wełny mineralnej akustycznej gr. 25cm, wsp. pochłaniania dźwięku = 1"},
  {photo: '/dworska/osowiec_srodek_dwa.jpg', heading: "Wyposażenie instalacyjne, izolacje przeciwwilogciowe, termiczne i akustyczne.", text: "W projektowanym obiekcie przewiduje się realizację wentylacji mechanicznej wywiewnej. Doprowadzenie wody do celów bytowych z wodociągu miejskiego z wykorzystaniem projektowanego przyłącza. Licznik zużycia ZWU pod blatem w kuchni. Podłączenie do sieci kanalizacyjnej z wykorzystaniem projektowanego przyłącza (wg. osobnej dokumentacji) Podłączenie do sieci elektrycznej NN złączem kablowym od skrzynki przyłączeniowej. Budynek przygotowany pod instalację pompy ciepła- bez osprzętu"},
]

const Dworska = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? specyfikacjaData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === specyfikacjaData.length - 1 ? 0 : prevIndex + 1));
  };
  return (
    <>
      <GalleryContainer>
        <GalleryImage src="/dworska/osowiec_zewnątrz.jpg" alt="Gallery Image 1" />
        <GalleryImage src="/dworska/osowiec_zewnatrz_dwa.jpg" alt="Gallery Image 1" />
        <GalleryImage src="/dworska/osowiec_srodek_jeden.jpg" alt="Gallery Image 1" />
        <GalleryImage src="/dworska/osowiec_srodek_dwa.jpg" alt="Gallery Image 1" />
        <GalleryImage src="/dworska/osowiec_drzwi.jpg" alt="Gallery Image 1" />
      </GalleryContainer>

      <Text>
        <span>Osiedle Dworska</span> Osiedle będzie bardzo nowocześnie wykończone. Każdy segment zawierał będzie instalacje kablową pod tak zwany "inteligentny dom", czyli tak zwany smart home. Dzięki temu wszystko dzieje się automatycznie, a urządzenia współpracują zgodnie bez naszej ingerencji. Dobrze skonfigurowany system jest nie tylko wygodny, ale przynosi również oszczędności.
      </Text>

      <MainZalety>
        <ZaletyHeadingMain>Zalety</ZaletyHeadingMain>
        <ZaletyGrid>
          <Zaleta>
            <ZaletaHeading>Kameralne Osiedle</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/house.png" alt="House Icon" />
            <ZaletaInfo>
                    Osiedle mieści 18 lokali mieszkalnych o powierzchni 89,46m2, każdy z działką około
                    500m2. Do każdego lokalu przynależne są 2 miejsca postojowe ułożone z kostki.
                    Dzięki położeniu Osiedla wśród drzew można cieszyć się ciszą, spokojem i uciec od
                    zgiełku miasta.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Komunikacja</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/train.png" alt="Train Icon" />
            <ZaletaInfo>
            15 minut pieszo do najbliższego przystanku autobusowego, z którego dostaniesz się
                    do centrum Grodziska Mazowieckiego. 7 minut do trasy szybkiego ruchu z wygodnym
                    dojazdem do centrum Warszawy lub Łodzi.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Relaks i sport</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/sports.png" alt="Sports Icon" />
            <ZaletaInfo>
            Dzięki temu, że Osowiec położony jest tylko 5 km od Grodziska Mazowieckiego gdzie
                    oferowane są rozrywki na wysokim poziomie. Basen, kino, liczne restauracje, a także
                    biblioteka to wszystko w zasięgu Twojej ręki.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Szkoły, przedszkola i żłobki</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/graduation-cap.png" alt="Graduation Icon" />
            <ZaletaInfo>
            Do najbliższej Szkoły podstawowej dzieli nas zaledwie 6 minut samochodem, żłobek 15
                    minut pieszo. Do Centrum Grodziska Mazowieckiego gdzie znajdują się liczne przedszkola
                    dostaniesz się w ciągu 15 minut.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Kultura</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/masks.png" alt="Masks Icon" />
            <ZaletaInfo>
            W Żabiej Woli i najbliższych okolicach można zobaczyć ciekawe pałace oraz zabytkowe
                    kościółki. Dwór w Żabiej Woli - dworek klasyczny wybudowany w XIXw Kościół Św.
                    Apostołów Piotra i Pawła w Skułach - drewniana świątynia z przełomu XVII i XVIII wieku.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Bliżej przyrody</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/nature.png" alt="Nature Icon" />
            <ZaletaInfo>
            Gmina Żabia Wola jest idealnym miejscem dla tych, którzy chcą odpocząć od nieustannego
                    zgiełku stolicy w miejscu zacisznym, czystym i atrakcyjnych pod względem przyrodniczym.
                    To taka ekologiczna ostoja, którą można przemierzać pieszo, na rowerach a nawet konno.
            </ZaletaInfo>
          </Zaleta>
        </ZaletyGrid>
    </MainZalety>

    <SecondHeading style={{marginBottom: "4rem"}}>
      Specyfikacja
    </SecondHeading>

    <SpecyfikacjaContainer>
      <ArrowButton className="left" onClick={handlePrev}>
        &lt;
      </ArrowButton>

      <SpecyfikacjaDiv>
        <SpecyfikacjaImage
          style={{
            backgroundImage: `url(${specyfikacjaData[currentIndex]?.photo})`,
          }}
        />
        <SpecyfikacjaDescription>
          <h4>{specyfikacjaData[currentIndex]?.heading}</h4>
          <p>{specyfikacjaData[currentIndex]?.text}</p>
        </SpecyfikacjaDescription>
      </SpecyfikacjaDiv>

      <ArrowButton className="right" onClick={handleNext}>
        &gt;
      </ArrowButton>
    </SpecyfikacjaContainer>

    <ZaletyHeadingMain>Dostępność Lokali</ZaletyHeadingMain>

    <TableWrapper>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh id="nrLOK" scope="col">
              Nr Lok.
            </StyledTh>
            <StyledTh id="powierzchniaLOK" scope="col">
              Powierzchnia m<sup>2</sup>
            </StyledTh>
            <StyledTh id="ogródLOK" scope="col">
              Ogród m<sup>2</sup>
            </StyledTh>
            <StyledTh id="miejscaLOK" scope="col">
              Miejsca postojowe
            </StyledTh>
            <StyledTh id="statusLOK" scope="col">
              Status
            </StyledTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1A</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">1B</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">2A</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">2B</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">3A</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">3B</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">4A</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
          <tr>
            <th scope="row">4B</th>
            <td>
              85,95 m<sup>2</sup>
            </td>
            <td>
              500 m<sup>2</sup>
            </td>
            <td>2</td>
            <td style={{color: "red"}}>Sprzedany</td>
          </tr>
        </tbody>
      </StyledTable>
      </TableWrapper>

      <SecondHeading>
        Localizacja Osiedla
      </SecondHeading>

      <Text style={{ textAlign: "center" }}>
        <span>Szczęsne koło Grodziska Mazowieckiego</span>, wjazd z ulicy Łagodnej.
      </Text>

      <Map src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2917.9171961254024!2d20.64183505171349!3d52.049393803918996!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2spl!4v1732566637695!5m2!1spl!2spl"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Map>
    </>
  );
};

export default Dworska;