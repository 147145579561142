import styled from "styled-components";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 4rem;
    text-align: center;
    padding: 1rem;

    h2{
        font-size: 2.6rem;
        font-weight: 500;

        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }

    h3{
        font-size: 2rem;
        font-weight: 500;
        
        @media (max-width: 768px) {
            font-size: 1.4rem;
        }
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;

        @media (max-width: 768px) {
            font-size: 1.15rem;
        }
    }

    p{
        font-size: 1rem;
    }

    img {
        width: 460px;

        @media (max-width: 768px) {
            width: 320px;
        }
    }

    ul{
        padding-left: .5rem;
        padding-right: .5rem;
        li {
            padding: 1rem;
            text-align: left;
        }
    }
`

const Finansowanie = () => {
    return(
        <Container>
            <h2>Mazowieckie Centrum Hipoteczne</h2>
            <img src="/logo_hipoteka.jpg"/>
            <h3>Firma Mazowieckie Centrum Hipoteczne to Eksperci z 10 letnim stażem na rynku kredytów hipotecznych.</h3>
            <p>Dzięki wieloletniemu doświadczeniu, są w stanie już na pierwszym spotkaniu policzyć zdolność, a także dobrać dla Państwa najlepszą ofertę ze wszystkich dostępnych na Polskim rynku Banków komercyjnych, a także w grupie Banków Spółdzielczych.</p>
            <h4>ATUTY WSPÓŁPRACY Z NASZYM EKSPERTEM:</h4>
            <ul>
                <li>bezpłatny, niezależny i bezstronny dobór najlepszych ofert kredytowych, indywidualna opieka,</li>
                <li>ponad 20 banków w jednym miejscu,</li>
                <li>prowadzenie całego procesu kredytowego z weryfikacją umów kredytowych i dokumentów nieruchomości,</li>
                <li>spotkanie w dogodnym miejscu i terminie,</li>
                <li>szybki kredyt dzięki dużemu doświadczeniu oraz dostępności do uproszczonych procedur.</li>

                <div style={{marginTop: "4rem"}}>
                    <p>Kontakt do mazowieckiego centrum hipotecznego</p>
                    <p>email: damian.kociolek@grupaang.pl</p>
                    <p>tel: 509-746-850</p>
                </div>
            </ul>
        </Container>
    )
}

export default Finansowanie;