import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Heading = styled.h1`
  font-size: 2.6rem;
  text-align: center;
  font-weight: 400;
  padding: 1rem;
`;

const Navigation = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
    position: relative;
  }
`;

const NavigationLinks = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  list-style: none;

  li {
    font-size: 1.1rem;
    cursor: pointer;
    position: relative;

    @media (max-width: 768px) {
      font-size: 1.4rem;

      &:hover{
        color: rgb(102, 156, 236);
      }
    }

    &:hover > ul {
      display: block;
    }
  }

  @media (max-width: 768px) {
    display: ${({ open }) => (open ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    gap: 2rem;
  }
`;

const SubMenu = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  background: white;
  padding: 0.5rem 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 180px;

  li {
    padding: 0.5rem 0;
    font-size: 1rem;

    &:hover {
      color: rgb(102, 156, 236);
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-left: 2rem;

  span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px 0;
    background: black;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const TopBar = styled.div`
  padding: .75rem;
  background-color: #f2f2f2;

  a{
    text-decoration: none;
  }
`

const TopBarContent = styled.div`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    width: 95%;
  }

  svg{
    fill: black;
    width: 18px;
  }

  p{
    font-size: 1.1rem;
    font-weight: 500;
    color: black;

    @media (max-width: 768px) {
    font-size: .95rem;
  }
  }
`

const Nav = ({ navItems, mobileNavItems }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [heading, setHeading] = useState("KMK DEVELOPMENT");

  const routeToHeadingMap = {
    "/nad-stawami": "Nad Stawami",
    "/osiedle-dworska": "Osiedle Dworska",
    "/osiedle-polna": "Osiedle Polna",
  };

  useEffect(() => {
    const currentHeading = routeToHeadingMap[location.pathname] || "KMK DEVELOPMENT";

    setHeading(currentHeading);

    localStorage.setItem("currentRoute", location.pathname);
  }, [location]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <TopBar>
        <a href="tel:664789127">
          <TopBarContent>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
            <p>Telefon Do Biura: 608072645</p>
          </TopBarContent>
        </a>
      </TopBar>
      <Heading>{heading}</Heading>

      <Navigation>
        <Hamburger onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </Hamburger>

        <NavigationLinks open={mobileMenuOpen}>
          <CloseButton onClick={toggleMobileMenu}>&times;</CloseButton>
          {mobileMenuOpen && mobileNavItems?.map((item, index) => (
            <li
              onClick={() => {
                if (item.route) {
                  navigate(item.route);
                  setMobileMenuOpen(false);
                }
              }}
              key={index}
            >
              {item.item}
            </li>
          ))}
          {!mobileMenuOpen && navItems?.map((item, index) => (
            <li
              onClick={() => {
                if (item.route) {
                  navigate(item.route);
                  setMobileMenuOpen(false);
                }
              }}
              key={index}
            >
              {item.item}
              {item.subItems && (
                <SubMenu>
                  {item.subItems.map((subItem, subIndex) => (
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(subItem.route);
                        setMobileMenuOpen(false);
                      }}
                      key={subIndex}
                    >
                      {subItem.item}
                    </li>
                  ))}
                </SubMenu>
              )}
            </li>
          ))}
        </NavigationLinks>
      </Navigation>
    </>
  );
};

export default Nav;