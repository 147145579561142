import styled from "styled-components";
import { useState } from "react";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 4rem;
  padding: 2rem;
  font-family: Arial, sans-serif;
  min-height: 60dvh;
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.4rem;
  text-align: center;
`;

const ContactInfo = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #555;

  span {
    font-weight: bold;
    color: #222;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Textarea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 0.8rem;
  background-color: rgb(102, 156, 236);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ResultMessage = styled.span`
  margin-top: 1rem;
  font-size: 1rem;
  color: ${({ isError }) => (isError ? "red" : "green")};
`;

const Kontakt = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [result, setResult] = useState("");
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    setIsError(false);

    const formData = new FormData();
    formData.append("access_key", "43aebf89-a6f2-47b6-ba9f-7bfbef542ed0");
    Object.entries(formState).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Sukces! Dziękujemy za kontakt.");
        setFormState({ name: "", email: "", message: "" });
      } else {
        setResult(data.message || "Something went wrong.");
        setIsError(true);
      }
    } catch (error) {
      setResult("Nie udalo się wyslać wiadomości.");
      setIsError(true);
    }
  };

  console.log(result)
  return (
    <Container>
      <Title>Kontakt W Sprawie Zakupu Mieszkania</Title>
      <ContactInfo>
        <span>Krzysztof Żukowski</span>, Prezes firmy.
        Tel: <span>608072645</span> E-mail: <span>k.zukowski@merkurion.pl</span>
      </ContactInfo>
      <ContactInfo>
        <span>Katarzyna Sawicka</span>, Kierownik działu marketingu i sprzedaży.
        Tel: <span>664789127</span> E-mail: <span>katarzyna@kmkmerkurion.pl</span>
      </ContactInfo>
      <Form onSubmit={onSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Imię"
          value={formState.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Twój Adres Email"
          value={formState.email}
          onChange={handleChange}
          required
        />
        <Textarea
          name="message"
          placeholder="Twoja Wiadomość"
          value={formState.message}
          onChange={handleChange}
          required
        />
        <Button type="submit">Wyślij</Button>
      </Form>
      {result && <ResultMessage isError={isError}>{result}</ResultMessage>}
    </Container>
  );
};

export default Kontakt;